import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>Hmmm, I'm not sure...</h1>
    <p>I can't seem to find the page you're looking for. All of our pages are grouped in the menu above. Maybe what you're looking for is up there!</p>
  </Layout>
)

export default NotFoundPage
